import React, { useState, useEffect, useRef } from 'react';
import {
  CarouselProvider,
  ButtonBack,
  ButtonNext,
  Slider,
  Slide,
  DotGroup,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { isMobile } from '../../utils/';
import ResourceCard from '../ResourceCard/ResourceCard';
import SectionIntro from '../SectionIntro';
import { Button } from '../Button';

import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

const Arrow = () => {
  return (
    <svg
      width="29"
      height="51"
      viewBox="0 0 29 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 49L26 25.5L2 2" stroke="#FFB600" strokeWidth="4" />
    </svg>
  );
};

const BlogPostCarousel = ({ items, cardLayout }) => {
  const carouselOptions = {
    totalSlides: items.length,
    infinite: false,
    isIntrinsicHeight: true,
    dragEnabled: false,
  };

  return (
    <div className="blog-post-carousel">
      <div className="blog-post-carousel__carousel">
        <CarouselProvider {...carouselOptions}>
          <div className="carousel-frame">
            <Slider>
              {items.map((item, index) => {
                return (
                  <Slide index={index} key={`blog-post-carousel__item${index}`}>
                    <div className="blog-post-carousel__item">
                      <ResourceCard
                        size="large"
                        layout={cardLayout}
                        {...item}
                      />
                    </div>
                  </Slide>
                );
              })}
            </Slider>

            <ButtonBack>
              <Arrow />
            </ButtonBack>
            <ButtonNext>
              <Arrow />
            </ButtonNext>
          </div>
          <DotGroup className="blog-post-carousel__dots" />
        </CarouselProvider>
      </div>
    </div>
  );
};

const ResourceCardCarousel = ({ items }) => {
  const carouselOptions = {
    totalSlides: items.length,
    infinite: true,
    isIntrinsicHeight: true,
    dragEnabled: false,
  };

  return (
    <div className="resource-card-carousel">
      <div className="resource-card-carousel__carousel">
        <CarouselProvider {...carouselOptions}>
          <Slider>
            {items.map((item, index) => {
              return (
                <Slide
                  index={index}
                  key={`resource-card-carousel__item${index}`}
                >
                  <div className="resource-card-carousel__item">
                    <ResourceCard {...item} />
                  </div>
                </Slide>
              );
            })}
          </Slider>

          <DotGroup className="resource-card-carousel__dots" />
        </CarouselProvider>
      </div>
    </div>
  );
};

const ResourceCardList = ({ items }) => {
  return (
    <div className="resource-card-list">
      <div className="resource-card-list__inner">
        {items?.slice(0, 3).map((item, index) => {
          return (
            <div className="resource-card-list__item" key={index}>
              <ResourceCard {...item} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ResourcesSection = (props) => {
  const {
    title,
    subtitle,
    ctaText,
    ctaUrl,
    featuredResources,
    featuredBlogPosts,
    stackResources,
  } = props;
  const useMobileLayout = isMobile();

  if (featuredResources === []) return null;

  const hasBlogCarousel = !!featuredBlogPosts && featuredBlogPosts.length !== 0;

  const [animation, setAnimation] = useState(null);

  const sectionRef = useRef();
  const ctaRef = useRef();
  const blogsRef = useRef();
  const resourcesRef = useRef();
  const introRef = useRef();

  useEffect(() => {
    // Do animations
    const toCommon = {
      duration: 0.75,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.5}`;

    const FadeIn = [
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 1,
        ease: 'power2.out',
      },
      delayCommon,
    ];
    const InFromBottom = [
      {
        opacity: 0,
        y: '25%',
      },
      {
        opacity: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const blogsEl = blogsRef.current;
    const ctaEl = ctaRef.current;
    const resourcesEl = resourcesRef.current;
    const introEl = introRef.current;

    let animation = gsap.timeline({}).fromTo(introEl, ...InFromBottom);

    if (hasBlogCarousel) {
      animation = animation.fromTo(blogsEl, ...FadeIn);
    }

    animation.fromTo(resourcesEl, ...FadeIn).fromTo(ctaEl, ...InFromBottom);
    setAnimation(animation);
    return () => {
      animation.progress(1);
      animation.kill();
    };  
  }, [useMobileLayout]);

  useEffect(() => {
    const sectionEl = sectionRef.current;
    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      onEnter: () => {
        if (animation) {
          animation.play();
        }
      },
      ...inViewTriggerThresholds,
      animation: animation,
    });

    return () => {
      trigger.kill();
    };
  }, [animation]);

  return (
    <section ref={sectionRef} className="resources-section">
      <div ref={introRef} className="resources-section__header">
        <SectionIntro title={title} subtitle={subtitle} />
      </div>
      <div className="resources-section__inner">
        {hasBlogCarousel && (
          <div ref={blogsRef} className="resources-section__blog_carousel">
            <h6 className="resources-section__section-subhead">
              Featured Resources
            </h6>
            <BlogPostCarousel
              cardLayout={useMobileLayout ? 'vertical' : 'horizontal'}
              items={featuredBlogPosts}
            />
          </div>
        )}

        <div ref={resourcesRef} className="resources-section__resource-cards">
          {hasBlogCarousel && (
            <h6 className="resources-section__section-subhead">
              Additional Resources
            </h6>
          )}
          {useMobileLayout && !stackResources ? (
            <ResourceCardCarousel items={featuredResources} />
          ) : (
            <ResourceCardList items={featuredResources} />
          )}
        </div>

        <div ref={ctaRef} className="resources-section__footer">
          <Button href={ctaUrl}>{ctaText}</Button>
        </div>
      </div>
    </section>
  );
};

ResourcesSection.defaultProps = {
  featuredResources: [],
  featuredBlogPosts: [],
  stackResources: true,
  ctaText: 'View resources',
  ctaUrl: '/resources',
};

export default ResourcesSection;
