import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../RichText';

const SectionIntro = ({ title, subtitle, theme, width }) => {
  return (
    <div
      className={`section-intro section-intro--${theme} section-intro--width-${width}`}
    >
      <div className="section-intro__inner">
        <h2 className="section-intro__title">{title}</h2>
        {subtitle && (
          <div className="section-intro__subtitle">
            {typeof subtitle.json !== 'undefined' ? (
              <RichText source={subtitle?.json} />
            ) : (
              subtitle
            )}
          </div>
        )}
      </div>
    </div>
  );
};

SectionIntro.propTypes = {
  width: PropTypes.oneOf(['limited', 'full']),
  theme: PropTypes.oneOf(['light', 'dark']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

SectionIntro.defaultProps = {
  width: 'limited',
  theme: 'light',
  title: 'Section Intro Heading Title',
  subtitle:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sit amet ex vitae sem ultricies malesuada ac vel neque. Vivamus lacinia scelerisque gravida.',
};

export default SectionIntro;
